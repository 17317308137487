<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">ارتباط با ما</h2>
      <p class="mt-5">
        لطفا قبل از تماس با من موارد زیر را با دقت بخوانید و برای وقت من و خودتان ارزش
        قائل شوید
      </p>
    </template>
    <template #default>
      <div
        class="p-5 bg-blueGray-100 border border-blueGray-200 rounded-md"
        id="group_ad"
      >
        <ul class="list-inside list-disc leading-10">
          <li>
            بودن ربات های من در گروه ها به این معنی نیست که من ادمین آن گروه ها هستم.
            تنظیمات ربات متفاوت است و هر ادمین تنظیمات دلخواه خود را انجام می‌دهد پس اگر
            مشکلی با ربات در گروهی دارید به ادمین همان گروه پیام دهید
          </li>
          <li>
            اگر ادمین گروه ها را پیدا نمیکنید وارد پنل شوید و از قسمت گروه های دیگر گروه
            مد نظر را انتخاب کرده و با زدن دکمه بیشتر می‌توانید ادمین های گروه را ببینید.
            اگر ادمین را مشاهده نمیکنید یعنی ادمین ناشناس است و حتی من هم نمی‌توانم آن را
            ببینم
          </li>
          <li>
            اگر مشکل یا سوالی در مورد موارد مختلف مانند سفارش تبلیغ، ربات گروه یا ربات
            کانال و ... دارید ابتدا از قسمت سوالات متداول دنبال جواب خود بگردید و اگر پیدا
            نکردید به من پیام دهید
          </li>
          <li>
            سعی کنید تا حد امکان تماس تلفنی نگیرید و در تلگرام به من پیام دهید
            <a href="https://t.me/mnr73" class="font-bold text-sky-500" dir="ltr">
              @mnr73
            </a>
            . سعی کنید سوالتان را بدون سلام و حرف های اضافه ارسال کنید تا در اسرع وقت جواب
            دهم
          </li>
          <li>
            ممنون می‌شوم اگر تا حد امکان از تماس تلفنی خودداری کرده و در تلگرام پیام دهید.
            با تشکر. میلاد نظری
            <a href="tel:09131262783" class="font-bold text-sky-500">09131262783</a>
          </li>
        </ul>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
export default {
  components: { BodySimple },
};
</script>
